import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';  // Ensure this path is correct
import heroImage from './image/home_bg.jpg';  // Correct path to the new image
import parnellVillage from './image/parnell_village.png';
import cityView from './image/city_view.png';
import beefTartare from './image/Beef_Tartare.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';


const HeroSection = () => {
    return (
      <div>
        {/* 原有的 hero-section */}
        <div className="hero-section" style={{ backgroundImage: `url(${heroImage})` }}>
          <img src={heroImage} alt="Seafood" style={{ display: "none" }} />
          <h1 className="hero-title">RFG Restaurant
          <p>1/333 Parnell Road, Parnell</p>
          <p>
            <a href="tel:0211082668" style={{ color: "white", textDecoration: "underline" }}>
              021 108 2668
            </a>{" "}
            |{" "}
            <a href="mailto:DINING@RFG.NZ" style={{ color: "white", textDecoration: "underline" }}>
              DINING@RFG.NZ
            </a>
          </p>
          <p>
          <a
            href="https://www.instagram.com/rfgrestaurant/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white", marginRight: "10px", textDecoration: "none" }}
            >
            <FontAwesomeIcon icon={faInstagram} className="instagram-icon" />
           </a>
          <span style={{ color: "white" }}>|</span>
          <a
            href="https://www.facebook.com/profile.php?id=61566921194200"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white", marginLeft: "10px", textDecoration: "none" }}
            >
           <FontAwesomeIcon icon={faFacebook} className="facebook-icon" />
           </a>
          </p>
          </h1>
          <Link to="/widget-reservation">
           <h2 className="hero-booklink">Book Now</h2>
          </Link>
        </div>
  
        {/* 新添加的 section */}
        <div className="parnell-section">
          {/* 添加带 SEO 的图片 */}
          <div className="parnell-text">
            <p>
              RFG Restaurant is elegantly situated within the historic charm of Parnell Village,
              housed in a century-old architectural gem. Secluded from the clamour of the busy streets,
              it offers a tranquil and sophisticated haven for diners.
            </p>
          </div>
          <div className="parnell-image-container">
           <img
             src={parnellVillage}  // 引入你的图片路径
             alt="Parnell Village"
             className="parnell-image"
           />
          </div>
        </div>

        <div className="cityview-section">
            <div className="city-text">
                <p>
                 On a summer evening when the air is filled with the fragrance of blooming flowers, 
                 you sit in RFG's serene private garden. A glass of fine wine, a handcrafted cocktail, 
                 or perhaps a refreshing spritz accompanies the exquisite dishes before you. 
                 The soothing rhythm of jazz weaves through the night, 
                 while the sparkling CBD skyline adds a touch of urban elegance. 
                 Every sip, every note, and every glance feels like a perfect harmony of nature, music, and modern sophistication.
                </p>
            </div>
            <div className="city-image-container">
                <img 
                 src={cityView}
                 alt="City View"
                 className="city-image"
                 />
            </div>
        </div>

        <div className="beef-section">
          <div className="beef-text">
            <p>
            The RFG’s menu is a symphony of modern French cuisine, showcasing the ocean’s freshest bounty, 
            tender cuts of perfectly grilled meats, and rich, succulent steaks. 
            Each dish is an artful balance of tradition and innovation, crafted with the finest local and imported ingredients. 
            Dining here is more than a meal—it is a poetic journey through exquisite flavours, timeless history, and refined sophistication.
            </p>
          </div>
          <div className="menubutton-link">
            <Link to="/menu">
             <p className="hero-menulink">Menu</p>
            </Link>
          </div>
          <div className="beef-image-container">
            <img src={beefTartare} alt="Beef Tartare" className="beef-image"/>
          </div>
        </div>
      </div>
    );
  };
  
  export default HeroSection;
  





