// src/Footer.js

import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-black text-white text-center p-4">
      <div>
        {/* 地址信息 */}
        <p>1/333 Parnell Road, Parnell, Auckland 1052</p>
        {/* 电话和邮箱信息 */}
        <p>
          <a
            href="tel:+64211082668"
            style={{ color: "white", textDecoration: "underline", marginRight: "10px" }}
          >
            +64 21 108 2668
          </a>
          |{" "}
          <a
            href="mailto:DINING@RFG.NZ"
            style={{ color: "white", textDecoration: "underline", marginLeft: "10px" }}
          >
            DINING@RFG.NZ
          </a>
        </p>
        {/* 营业时间信息 */}
        <p>Monday - Sunday from 4:30 pm, Close on Wednesday</p>
      </div>
      {/* 社交媒体信息 */}
      <div>
        <p>
         <a href="https://www.instagram.com/rfgrestaurant/" target="_blank" rel="noopener noreferrer" style={{ color: "white", marginRight: "10px", textDecoration: "none" }}>
            <FontAwesomeIcon icon={faInstagram} className="instagram-icon" />
         </a>
            <span style={{ color: "white" }}>|</span>
         <a href="https://www.facebook.com/profile.php?id=61566921194200" target="_blank" rel="noopener noreferrer" style={{ color: "white", marginLeft: "10px", textDecoration: "none" }}>
            <FontAwesomeIcon icon={faFacebook} className="facebook-icon" />
         </a>
        </p>
      </div>
      {/* 底部版权信息 */}
      <div>
        © {new Date().getFullYear()} RFG. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;

