import React, { useState, useEffect } from 'react';
import './MenuPage.css'; // 导入 CSS 文件
import menuBg from './image/Lamb_rib.png'; // 导入图片

const MenuPage = () => {
  const [activeTab, setActiveTab] = useState('a-la-carte'); // 保存当前选中的 Tab

  useEffect(() => {
    document.title = "RFG Menu"; // 设置页面标题
  }, []);

  // 定义不同 Tab 的内容
  const renderMenuContent = () => {
    switch (activeTab) {
      case 'a-la-carte':
        return (
          <div>
            <h2>À La Carte Menu</h2>
  
            {/* 第一部分: Amuse-bouche */}
            <h3>Amuse-bouche</h3>
            <ul>
              <li>
                <strong>Fresh Jumbo Oyster</strong> <br />
                Cognac Sauce or Sake Sauce <br />
                Fermented Fruit Slush + $3/each - <strong>$7/each</strong>
              </li>
              <li>
                <strong>Beef Tartare</strong> <br />
                Capers, Onion, Dijon, Wasabi - <strong>$9/each</strong>
              </li>
              <li>
                <strong>Pan-seared Scallop</strong> <br />
                Pumpkin and Champagne - <strong>$9/each</strong>
              </li>
            </ul>
  
            {/* 第二部分: Entrées */}
            <h3>Entrées</h3>
            <ul>
              <li>
                <strong>Bluefin Tuna Tartare</strong> <br />
                Granny Smith, Fermented Apple, Mint, Yuzu, Szechuan Pepper, Pickles - <strong>$18</strong>
              </li>
              <li>
                <strong>Torched Salmon</strong> <br />
                Grapefruit, Yuzu and Basil - <strong>$23</strong>
              </li>
              <li>
                <strong>Charcoal Grill Argentina Red Prawn</strong> <br />
                Garlic Chill Sauce - <strong>$20</strong>
              </li>
              <li>
                <strong>Slow Cooked Lamb Rib</strong> <br />
                Honest Rum Yogurt, Fennel Salad, Herbs - <strong>$14</strong>
              </li>
              <li>
                <strong>Fried Cheese Dumplings</strong> <br />
                Brie & Mozzarella, Honey, Grapefruit, La Cigogne - <strong>$12</strong>
              </li>
              <li>
                <strong>King Salmon Salad</strong> <br />
                Grilled King Salmon, White Quinoa, Capers, Mandarin, Yuzu - <strong>$22</strong>
              </li>
            </ul>
  
            {/* 第三部分: Soupes */}
            <h3>Soupes</h3>
            <ul>
              <li>
                <strong>Crayfish Bisque</strong> <br />
                Scampi, Cream and Vegetable Dumplings - <strong>$28</strong>
              </li>
            </ul>
  
            {/* 第四部分: Les Plats Principaux */}
            <h3>Les Plats Principaux (Mains)</h3>
            <ul>
              <li>
                <strong>Market Fish</strong> <br />
                Baby Pea Puree - <strong>$38</strong>
              </li>
              <li>
                <strong>Slow Cooked Lamb Ribs</strong> <br />
                Honest Rum Yogurt, Fennel Salad, Herbs - <strong>$38</strong>
              </li>
              <li>
                <strong>Vegetable Dumplings</strong> <br />
                Mushroom, Carrot, Truffle Potato, Broccoli, Asparagus, Cheese - <strong>$28</strong>
              </li>
              <li>
                <strong>RFG Beef Wellington</strong> <br />
                Eye fillet, Mixed Mushrooms, Truffle, Prosciutto (Take 1.5 hrs to cook) - <strong>$130</strong>
              </li>
            </ul>
  
            {/* 第五部分: Kamado Grill */}
            <h3>Kamado Grill</h3>
            <ul>
              <li>
                <strong>Eye Fillet (160g)</strong> <br />
                Grass-Fed, AU - <strong>$38</strong>
              </li>
              <li>
                <strong>Angus Scotch Fillet (300g)</strong> <br />
                Grass-Fed Angus, Hawke’s Bay, NZ - <strong>$46</strong>
              </li>
              <li>
                <strong>Tomahawk (900g)</strong> <br />
                Grass-Fed, NZ (Take 1.5 hrs to cook) - <strong>$120</strong>
              </li>
              <li>
                <strong>28-Days Dry Aged Angus Sirloin</strong> <br />
                Grass-Fed Angus, Hawke’s Bay, NZ - <strong>$28/100g</strong>
              </li>
              <li>
                <strong>28-Days Dry Aged Eye Fillet</strong> <br />
                Grass-Fed, Hawke’s Bay, NZ - <strong>$28/100g</strong>
              </li>
              <li>
                <strong>28-Days Dry Aged Grain-Fed Angus Tomahawk</strong> <br />
                Grain-Fed Angus, Canterbury, NZ - <strong>$32/100g</strong>
              </li>
              <li>
                <strong>45-Days Dry Aged Tomahawk</strong> <br />
                Grass-Fed, Hawke’s Bay, NZ - <strong>$38/100g</strong>
              </li>
              <li>
                <strong>A5 Wagyu Sirloin</strong> <br />
                Kagoshima, Japan (Booking Required) - <strong>$55/100g</strong>
              </li>
            </ul>
  
            {/* 第六部分: Accompagnments */}
            <h3>Accompagnments (Sides)</h3>
            <ul>
              <li>
                <strong>Honey-Roasted Carrot</strong> <br />
                With Sesame Oil - <strong>$12</strong>
              </li>
              <li>
                <strong>Cream Asparagus</strong> <br />
                Ham - <strong>$15</strong>
              </li>
              <li>
                <strong>Mix Vegetables</strong> <br />
                Potato, Carrot, Eggplant, Courgette - <strong>$16</strong>
              </li>
            </ul>
  
            {/* 最后一部分: 提示信息 */}
            <p style={{ fontSize: '12px', marginTop: '20px' }}>
              <em>
                ALL DISHES MAY CONTAIN TRACES OF GLUTEN, NUTS & DAIRY. PLEASE INFORM OUR TEAM OF ANY SPECIAL DIETARY REQUIREMENTS.
              </em>
            </p>
          </div>
        );
      case 'sharing-food-menu':
        return (
          <div>
          <h2>RAW FLAME GRILL</h2>
          <ul>
            <li>
              <strong>Whole RFG Tomahawk - $120</strong> <br />
              900g <br />
              Charcoal grilled, Red Wine Jus, Horseradish, Homemade Soy Sauce <br />
              (Serves 2-3 people, 1 Day Booking Ahead)
            </li>
            <li>
              <strong>RFG Full Rack Lamb Rack - $130</strong> <br />
              12 Racks <br />
              Parsley, Dijon Mustard, Mint <br />
              (Serves 2 people, 1 Day Booking Ahead)
            </li>
            <li>
              <strong>RFG Beef Wellington - $130</strong> <br />
              Eye fillet, Mixed Mushrooms, Truffle Pasta, Prosciutto <br />
              (Serves 2-3 people, 1 Day Booking Ahead)
            </li>
            <li>
              <strong>RFG GAME MEAT - $160</strong> <br />
              3 Kinds of Wild Game Meat (Wild Venison, Wild Pork, Wild Quail, Wild Rabbit, Wild Hare, Wallaby, Wild Goat, Wild Arapawa Sheep, Wild Fallow), Game Sauce, Beetroot Sauce <br />
              (Serves 2 people, 1 Week Booking Ahead, Subject to Availability)
            </li>
          </ul>
          <p><em>All are served with seasonal roast vegetables and salad.</em></p>
        </div>

        );
      case 'beverage-list':
        return(
          <div>
      {/* Cocktails */}
      <h2>Cocktails</h2>
      <ul>
        <li>
          <strong>Cold Brew Coffee Martini</strong> - $23 <br />
          Tito’s Vodka, Cold Brew Coffee, Mr. Black Coffee Liqueur, Vanilla
        </li>
        <li>
          <strong>French Martini</strong> - $20 <br />
          Tito’s Vodka, Chambord, Pineapple
        </li>
        <li>
          <strong>Passion Passion</strong> - $23 <br />
          Tito’s Vodka, Passion Fruit, Lime
        </li>
        <li>
          <strong>Cosmopolitan</strong> - $20 <br />
          Tito’s Vodka, Cointreau, Cranberry, Lime
        </li>
        <li>
          <strong>Aviation</strong> - $20 <br />
          Hayman’s Gin, Maraschino, Violette, Lemon
        </li>
        <li>
          <strong>Shiso Mojito</strong> - $23 <br />
          Diplomatico Rum, Shiso, Mint, Lime, Bubble
        </li>
        <li>
          <strong>Dark & Stormy</strong> - $20 <br />
          Flor de Cana 7 aged Rum, Lime, Ginger Beer
        </li>
        <li>
          <strong>Margarita</strong> - $20 <br />
          Altos Tequila, Cointreau, Agave, Lime, Salt
        </li>
        <li>
          <strong>Hawke’s Bay Negroni</strong> - $24 <br />
          Hawke’s Bay Gin, Hawke’s Bay Campari, Antica Formula
        </li>
        <li>
          <strong>Old Fashioned</strong> - $24 <br />
          Woodford Reserve Bourbon, Angostura, Orange, Sugar
        </li>
        <li>
          <strong>The Martini</strong> - MP <br />
          Vodka OR Gin, Dry OR Dirty, Olive OR Twist
        </li>
      </ul>

      {/* Wine By Glass */}
      <h2>Wine By Glass</h2>
      <ul>
        <li>
          <strong>Le Contesse Prosecco Brut</strong> - $13/$50 <br />
          Italy
        </li>
        <li>
          <strong>Bird in Hand Sparkling Pink</strong> - $16/$55 <br />
          AU
        </li>
        <li>
          <strong>NV Laurent-Perrier La Cuvée Brut Champagne</strong> - $26/$115 <br />
          Tours-sur-Marne, France
        </li>
        <li>
          <strong>NV Gosset Grande Reserve Brut Champagne</strong> - $28/$120 <br />
          Ay, France
        </li>
        <li>
          <strong>La Grande Colombe Chardonnay</strong> - $16/$55 <br />
          France
        </li>
        <li>
          <strong>Greywacke Sauvignon Blanc</strong> - $17/$65 <br />
          Marlborough, NZ
        </li>
        <li>
          <strong>Prophet’s Rock Pinot Gris</strong> - $18/$75 <br />
          Martinborough, NZ
        </li>
        <li>
          <strong>Saint Clair Gewürztraminer</strong> - $17/$65 <br />
          Marlborough, NZ
        </li>
        <li>
          <strong>Triennes Rosé</strong> - $16/$55 <br />
          Provence, France
        </li>
        <li>
          <strong>CherryCream Pinot Noir</strong> - $17/$65 <br />
          California, USA
        </li>
        <li>
          <strong>Pencarrow Pinot Noir</strong> - $18/$75 <br />
          Martinborough, NZ
        </li>
        <li>
          <strong>Langmeil Valley Floor Shiraz</strong> - $17/$65 <br />
          Barossa Valley, AU
        </li>
        <li>
          <strong>Taylor’s 10 Years Tawny Port</strong> - $25/75ml <br />
          Portugal
        </li>
      </ul>

      {/* Wine By Bottle */}
      <h2>Wine By Bottle</h2>
      <ul>
        <li>
          <strong>Corkage Fee</strong> - $20/Bottle
        </li>
        <li>
          <strong>NV Gosset Blanc de Blanc Champagne</strong> - $190 <br />
          Ay, France
        </li>
        <li>
          <strong>2016 Clos Henri “Clay” Sauvignon Blanc</strong> - $180 <br />
          Marlborough, NZ
        </li>
        <li>
          <strong>2021 Prophet’s Rock Antipodes Pinot Noir</strong> - $170 <br />
          Martinborough, NZ
        </li>
        <li>
          <strong>2020 Rockburn Pinot Noir</strong> - $80 <br />
          Central Otago, NZ
        </li>
        <li>
          <strong>2017 Cloudy Bay Te Wahi Pinot Noir</strong> - $260 <br />
          Central Otago, NZ
        </li>
        <li>
          <strong>2022 Felton Road “Calvert” Pinot Noir</strong> - $180 <br />
          Central Otago, NZ
        </li>
        <li>
          <strong>2022 Felton Road “Cornish Point” Pinot Noir</strong> - $180 <br />
          Central Otago, NZ
        </li>
        <li>
          <strong>2013 Man O’ War Kulta Tytti Bordeaux Blend</strong> - $280 <br />
          Waiheke Island, NZ
        </li>
        <li>
          <strong>2020 John Duval Entity Shiraz</strong> - $90 <br />
          Barossa, AU
        </li>
      </ul>

      {/* Spirits */}
      <h2>Spirits</h2>
      <ul>
        <li>
          <strong>House Pour</strong> - $14/30ml +$4 mixers <br />
          Tito’s Vodka | Diplomatico Rum | Flor de Cana 7 Aged Rum | Hayman’s London Dry Gin | Woodford Reserve Bourbon | Altos Tequila
        </li>
        <li>
          <strong>Gin</strong>
          <ul>
            <li>
              <strong>East Block 200 Gin</strong> - $15/30ml <br />
              Hawke’s Bay, NZ
            </li>
            <li>
              <strong>The Botanist</strong> - $16/30ml <br />
              Scotland
            </li>
            <li>
              <strong>Monkey 47 Dry Gin</strong> - $18/30ml <br />
              Germany
            </li>
          </ul>
        </li>
        <li>
          <strong>Whisky</strong>
          <ul>
            <li>
              <strong>Woodford Reserve Double Oaked</strong> - $20/30ml
            </li>
            <li>
              <strong>Jura Seven Wood</strong> - $20/30ml
            </li>
            <li>
              <strong>Glenmorangie 14 Port Cask</strong> - $20/30ml
            </li>
            <li>
              <strong>1994 Benriach Cask 1858 PX Sherry 27 yro</strong> - $58/30ml
            </li>
            <li>
              <strong>Kavalan Solist Moscatel Sherry</strong> - $32/30ml
            </li>
            <li>
              <strong>Signatory Glen Scotia 1991 22 Years</strong> - $52/30ml
            </li>
          </ul>
        </li>
      </ul>

      {/* Beer */}
      <h2>Beer</h2>
      <ul>
        <li>
          <strong>Asahi Dry</strong> - $11
        </li>
        <li>
          <strong>Asahi Zero 0%</strong> - $10
        </li>
        <li>
          <strong>Hallertau #2 Pale Ale</strong> - $13
        </li>
        <li>
          <strong>Hallertau #1 Low Carb Lager</strong> - $13
        </li>
      </ul>

      {/* Cellar List */}
      <h2>Cellar List</h2>
      <ul>
        <li>
          <strong>Laurent-Perrier Grand Siècle No.26</strong> - $600
        </li>
        <li>
          <strong>Bollinger R.D 2008 Champagne</strong> - $690
        </li>
        <li>
          <strong>1993 Cuvee Dom Perignon Melesime</strong> - $750
        </li>
        <li>
          <strong>2008 Dom Perignon Brut Champagne</strong> - $790
        </li>
        <li>
          <strong>2008 Dom Perignon Chef De Cave Legacy</strong> - $830
        </li>
        <li>
          <strong>Bollinger Vieilles Vignes Francaises 2012</strong> - $3980
        </li>
        <li>
          <strong>2015 Leroy Bourgogne Rouge</strong> - $460
        </li>
        <li>
          <strong>1998 Chateau Cheval Blanc Grand Cru</strong> - $1980
        </li>
        <li>
          <strong>2008/2012 Domaine Jacques-Frederic Mugnier Musigny</strong> - $4800
        </li>
        <li>
          <strong>1999 Domaine de La Romanee-Conti Richebourg Grand Cru</strong> - $15800
        </li>
        <li>
          <strong>2016 Petrus</strong> - $7500
        </li>
        <li>
          <strong>1992 Fonseca Vintage Port</strong> - $75/75ml
        </li>
        <li>
          <strong>2017 Chateau d’Yquem Sauternes 375ml</strong> - $68/30ml, $680/375ml
        </li>
      </ul>
    </div>

        );
      default:
        return null;
    }
  };

  return (
    <div className="page-container">
      <div className="content-container">

        {/* 顶部部分 */}
        <div className="top-section">
          <div className="menu-image-section">
            <img src={menuBg} alt="Lamb rib" className="menu-image" />
          </div>
        </div>

        {/* 菜单标签部分 */}
        <div className="menu-tabs-section">
          <a
            href="#"
            className={`menu-tab ${activeTab === 'a-la-carte' ? 'active' : ''}`}
            onClick={() => setActiveTab('a-la-carte')}
          >
            À La Carte
          </a>
          <a
            href="#"
            className={`menu-tab ${activeTab === 'sharing-food-menu' ? 'active' : ''}`}
            onClick={() => setActiveTab('sharing-food-menu')}
          >
            Sharing Food Menu
          </a>
          <a
            href="#"
            className={`menu-tab ${activeTab === 'beverage-list' ? 'active' : ''}`}
            onClick={() => setActiveTab('beverage-list')}
          >
            Beverage List
          </a>
        </div>

        {/* 动态内容部分 */}
        <div className="menu-section">
          {renderMenuContent()}
        </div>
      </div>
    </div>
  );
};

export default MenuPage;
































