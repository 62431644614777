import React from 'react';
import './App.css'; 
import backgroundImage from './image/contactbg.jpg'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';

const ContactInfo = () => {
    const navigate = useNavigate();

    const handleReservationClick = (event) => {
        event.preventDefault();
        navigate('/reservation');
    };

    return (
        <div className="contact-page-container">
            <div className="contact-image-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <h1 className="contact-title">Contact</h1>
            </div>
            <div className="title-section">
                <h2 className="page-title">RFG</h2>
            </div>
            <div className="contact-details-section">
                <div className="left-section">
                    <p><FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" /> 1/333 Parnell Road, Auckland 1052</p>
                    <p><FontAwesomeIcon icon={faPhoneAlt} className="contact-icon" /> (+64) 021 108 2668</p>
                    <p><FontAwesomeIcon icon={faEnvelope} className="contact-icon" /> DINING@RFG.NZ</p>
                    <a href="https://www.instagram.com/rfgrestaurant/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} className="instagram-icon" />{' '}
                        rfgrestaurant
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61566921194200" target="_blank" rel="noopener noreferrer">
                       <FontAwesomeIcon icon={faFacebook} className="facebook-icon" />{' '}
                       RFG Restaurant
                    </a>
                </div>
                <div className="right-section">
                    <p className="get-in-touch">Opening hours</p>
                    <p>Monday - Sunday<br />From 4:30pm<br />Close On Wednesday</p>
                </div>
            </div>
        </div>
    );
}

export default ContactInfo;














